import localeEnglish from '@angular/common/locales/en';
import localeGreece from '@angular/common/locales/el';
import { isPlatformBrowser, isPlatformServer, registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class LocaleService {

    private _locale: string; //= 'el'

    set locale(value: string) {
        this._locale = value;
    }
    get locale(): string {
        return this._locale || localStorage.getItem("language");
    }

    public readLocale() {
        return this._locale;
    }

    registerCulture(culture: string) {
        if (!culture) {
            return;
        }
        this._locale = culture;
        // setTimeout(() => {
        //     this.locale = culture;
        //     // registerLocaleData(localeEnglish);
        // }, 1000);
        switch (culture) {
            case 'en': {
                this._locale = 'en';
                setTimeout(() => {
                    registerLocaleData(localeEnglish);
                }, 1000);
                break;
            }
            case 'el': {
                this._locale = 'el';
                setTimeout(() => {
                    registerLocaleData(localeGreece);
                }, 1000);
                break;
            }
        }
    }
}